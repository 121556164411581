const topicSlug = {
  nasTym: "nas-tym/",
  prednaskyKonference: "prednasky-konference/",
  pripadoveStudie: "pripadove-studie/",
  napsaliONas: "napsali-o-nas/",
};

export const Content = {
  nasTym: {
    title: "Náš tým",
    slug: topicSlug.nasTym,
    description: `Spolupracovat budete výhradně s certifikovanými daňovými poradci a kvalifikovanými účetními tak, aby veškeré výstupy byly precizní, včas a mohli jste se na nás spolehnout. Celý tým zároveň funguje efektivně jako celek, s ohledem nejen na profesní specializaci, ale také s respektem k osobnostním profilům členů a důrazem na rozvoj soft skills. Díky tomu můžete očekávat nejen perfektní daňové a účetní výstupy, ale také profesionální jednání a snadnou komunikaci.`,
    photos: [],
    photosDescription: [],
  },
  prednaskyKonference: {
    title: "Přednášky / konference",
    slug: topicSlug.prednaskyKonference,
    description: `Připravujeme školení a přednášky na míru, vedeme pravidelné semináře a vystupujeme také na
      odborných konferencích. Nezahrneme Vás teorií, ale klademe maximální důraz na to, aby poskytnuté
      informace byly ihned využitelné v praxi.`,
  },
  pripadoveStudie: {
    title: "Případové studie",
    slug: topicSlug.pripadoveStudie,
    description: `Máme zkušenosti s registrací stovek daňových subjektů k DPH, daňovými řešeními restrukturalizací,
    implementacemi ERP systémů i analýzami převodních cen. V případových studiích Vám představíme
    konkrétní projekty a výsledky.`,
  },
};
export default Content;
